import Vue from 'vue'
import App from './App.vue'
import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue';
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'

Amplify.configure({
    "aws_project_region": process.env.VUE_APP_AWS_PROJECT_REGION,
    "aws_cognito_identity_pool_id": process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.VUE_APP_AWS_COGNITO_REGION,
    "aws_user_pools_id": process.env.VUE_APP_AWS_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    "oauth": {}
});

Vue.config.productionTip = false

store.dispatch("requestMyPilot")

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
