<template>
  <amplify-sign-up
      slot="sign-up"
      :form-fields.prop="formFields">
  </amplify-sign-up>
</template>

<script>
export default {
  name: "SignUp",
  data() {
    return {
      formFields: [
        {
          type: 'username',
          required: true
        },
        {
          type: 'email',
          required: true
        },
        {
          type: 'password',
          required: true
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>