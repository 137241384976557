<template>
  <div>
    <v-container>
      <v-row>
        <v-col offset-sm="0" offset-md="1" offset-lg="2" md="2" xl="1" style="max-width: 215px">
          <v-sheet class="" rounded="lg">
            <v-list color="transparent">
              <v-list-item
                v-for="link in links"
                :key="link.title"
                link
                :to="link.route"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ link.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider class="my-2"></v-divider>

              <v-list-item link color="">
                <v-list-item-content>
                  <v-list-item-title> Refresh </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-sheet>
        </v-col>

        <v-col sm="8" md="6">
          <v-sheet min-height="70vh" rounded="lg" class="">
            <router-view name="admin"></router-view>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        { title: "Ships", route: { name: "admin-console-ships" } },
        { title: "Data Loaders", route: { name: "admin-console-loaders" } },
      ],
    };
  },
};
</script>
<style scoped>
.sheet-dark {
  background-color: #183a50;
  color: white;
}
</style>
