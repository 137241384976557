<template>
  <v-app-bar
    absolute
    color="#43a047"
    dark
    shrink-on-scroll
    prominent
    src="@/assets/SC_01_Wallpaper_3840x2160.jpg"
    fade-img-on-scroll
    scroll-target="#scrolling-techniques-5"
    scroll-threshold="500"
    app
  >
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, rgba(10,86,137,.7), rgba(25,32,72,.7)"
      ></v-img>
    </template>
    <v-container class="py-0 fill-height">
      <v-avatar class="mr-10" color="teal darken-3" size="80">
        <!-- <img src="@/assets/scfleets-2.png" alt="SC Fleets Icon" /> -->
        <span class="white--text headline">SCF</span>
      </v-avatar>

      <v-btn :to="link.route" v-for="link in links" :key="link.title" text>
        {{ link.title }}
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn :to="{ name: 'the-profile' }" icon>
        <v-avatar v-if="me.rsiProfileImgUrl" color="teal darken-3" >
           <v-img :src="'https://robertsspaceindustries.com' + me.rsiProfileImgUrl" :alt="me.rsiHandle + 'profile image'" />
        </v-avatar>
        <v-avatar v-else color="teal darken-3">
          <!-- <img src="@/assets/scfleets-2.png" alt="SC Fleets Icon" /> -->
          <span class="white--text headline">{{ iconText }}</span>
        </v-avatar>
      </v-btn>
      <v-btn @click="logOut" text>Log Out</v-btn>
    </v-container>
  </v-app-bar>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      links: [
        { title: "Dashboard", route: { name: "the-dashboard" } },
        { title: "Fleets", route: { name: "fleet-listings" } },
        { title: "Hanger", route: { name: "the-hanger" } },
        { title: "Admin", route: { name: "admin-console-ships" } },
      ],
    };
  },
  computed: {
    ...mapGetters({ me: "getMyPilot" }),
    iconText: function() {
      const username = this.me && this.me.rsiHandle || this.me.userName || "PILOT"
      return username.substr(0,1).toUpperCase() + username.substr(username.length-1,username.length-1).toUpperCase()|| "PT"
    }
  },
  methods: {
    logOut() {
      Auth.signOut()
        .then(() => {
          console.log("signout success");
        })
        .catch((error) => {
          console.error("signout failed", error);
        });
    },
  }
};
</script>
