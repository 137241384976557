<template>
  <div class="TheProfile">
    <v-flex>
      <v-card class="grey darken-3">
        <v-card-title>RSI Profiles <v-btn color="blue" @click="getMyPilot" icon><v-icon>mdi-refresh</v-icon></v-btn></v-card-title>
        <v-list class="grey darken-2" min-height="400" three-line>
          <profile-row v-for="(profile,key) in me.rsiProfileMap" :profile="profile" :handle="key" :key="key" :defaultProfile="key === me.defaultProfile"
                       :edit="edit"/>
          <v-list-item class="grey darken-1" v-if="adding">
            <v-list-item-content class="grey darken-1">
              <v-text-field
                  label="Add RSI Handle"

                  hide-details="auto"
                  v-model="newHandle"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn @click="saveNewHandle" v-if="adding">
            Save
          </v-btn>
          <v-btn v-else-if="edit" @click="toggleEdit">
            Done
          </v-btn>
          <v-btn v-else @click="toggleAdding">
            <v-icon color="blue lighten-1">mdi-plus</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="!edit || !adding" @click="toggleEdit">
            <v-icon color="blue lighten-1">mdi-cog</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProfileRow from "@/components/profile/pilot-content/ProfileRow";

export default {
  name: 'TheProfile',
  components: {ProfileRow},
  data() {
    return {
      edit: false,
      overlay: true,
      adding: false,
      newHandle:''
    }
  },
  computed: {
    ...mapGetters({me: "getMyPilot"}),
  },
  methods: {
    toggleAdding() {
      this.adding = !this.adding
    },
    toggleEdit() {
      this.edit = !this.edit
    },
    save() {
      this.edit = !this.edit
    },
    saveNewHandle() {
      console.log('Saving handle: ',this.newHandle)
      this.$store.dispatch("addRsiProfileMyPilot",this.newHandle);
      this.toggleAdding()
    },
    getMyPilot(){
      this.$store.dispatch("requestMyPilot");
    }
  }
};
</script>
