<template>
  <div>
<!--    <v-container fluid>-->
<!--      <v-row justify="center">-->
<!--        <v-col md="1" v-for="ship in ships" :key="ship.id">-->
<!--          <v-card class="mx-auto" >-->
<!--            <v-img :src="ship.imgUrl" height="200px"></v-img>-->
<!--            <v-card-title>{{ ship.name }}</v-card-title>-->
<!--            <v-card-subtitle>{{ ship.manufacturerName }}</v-card-subtitle>-->
<!--          </v-card>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--    </v-container>-->
    <v-data-table
      :headers="headers"
      :items="ships"
    >
      <template v-slot:item.imgUrl="{ item }">
        <v-avatar
            size="56"
        >
          <img
              style="object-fit: cover;"
              :src="item.imgUrl"
              :alt="item.name + 'Icon'"
          >
        </v-avatar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({ ships: "getShips" }),
  },
  methods: {},
  created() {
    this.$store.dispatch("requestShips");
  },
  data() {
    return {
      headers:[
        {
          text: '',
          value:'imgUrl'
        },
        {
          text:'Name',
          value:'name'
        },
        {
          text:'Focus',
          value:'focus'
        },
        {
          text:'Code',
          value: 'manufacturerCode'
        }
      ]
    }
  }
};
</script>
