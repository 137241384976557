import Api from '../utils/Api'

export default {
  state: {
    pilots: [],
    me: {}
  },
  mutations: {
    SET_PILOTS: (state, pilots) => {
      state.pilots = pilots;
    },
    SET_PILOT: (state, pilot) => {
      state.pilot = pilot;
    },
    SET_MY_PILOT: (state, pilot) => {
      state.me = {
        ...pilot,
        ...pilot.rsiProfileMap[pilot.defaultProfile]
      };
    },
    ADD_SHIP_MY_PILOT:(state, newShip) => {
      state.me = {
        ...state.me,
        ships:[
          ...state.me.ships,
          newShip
        ]
      }
    },
    REMOVE_PILOT_PROFILE: (state, rsiHandle) => {

      const newState = {
        ...state.me
      }
      delete newState.rsiProfileMap[rsiHandle]

      state.me = newState;
    }
  },
  actions: {
    requestPilots: ({ commit }) => {
      Api.get(`${process.env.VUE_APP_API_SERVER}/pilots/`)
        .then((response) => {
          commit("SET_PILOTS", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    requestPilot: ({ commit },id) => {
      Api.get(`${process.env.VUE_APP_API_SERVER}/pilots/${id}/`)
        .then((response) => {
          commit("SET_PILOT", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
      },
    requestMyPilot: ({ commit }) => {
      Api.get(`${process.env.VUE_APP_API_SERVER}/pilots/me/`)
        .then((response) => {
          commit("SET_MY_PILOT", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addRsiProfileMyPilot: ({ commit }, rsiHandle) => {
      Api.post(`${process.env.VUE_APP_API_SERVER}/pilots/me/profiles/`, {rsiHandle: rsiHandle})
          .then((response) => {
            commit("SET_MY_PILOT", response.data);
          })
          .catch((error) => {
            console.error(error);
          });
    },
    removeRsiProfileMyPilot: ({ commit }, rsiHandle) => {
      Api.delete(`${process.env.VUE_APP_API_SERVER}/pilots/me/profiles/${rsiHandle}/`)
          .then(() => {
            commit("REMOVE_PILOT_PROFILE", rsiHandle);
          })
          .catch((error) => {
            console.error(error);
          });
    },
    addRsiProfile: ({ commit },id) => {
      Api.get(`${process.env.VUE_APP_API_SERVER}/pilots/${id}/profile/`)
          .then((response) => {
            commit("SET_MY_PILOT", response.data);
          })
          .catch((error) => {
            console.error(error);
          });
    },
    verifyRsiProfile: ({commit}, {rsiHandle, verificationCode}) => {
      console.log(rsiHandle,verificationCode)
      Api.post(`${process.env.VUE_APP_API_SERVER}/pilots/me/profiles/${rsiHandle}/verify/`,{verificationCode:verificationCode})
          .then((response) => {
            commit("SET_MY_PILOT", response.data);
          })
          .catch((error) => {
            console.error(error);
          });
    },
    addNewShip: ({ commit },shipId) => {
      Api.post(`${process.env.VUE_APP_API_SERVER}/pilots/me/ships/?shipId=${shipId}`)
          .then((response) => {
            commit("ADD_SHIP_MY_PILOT", response.data);
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
  getters: {
    getPilots: (state) => {
      return state.pilots;
    },
    getPilot: (state,id) => {
      return state.pilots[id];
    },
    getMyPilot: (state) => {
      return state.me;
    }
  },
};
