<template>
  <div>
    <v-list-item>
      <v-list-item-avatar>
        <v-img
            :alt="`${ship.name} avatar`"
            :src="ship.imgUrl"
        ></v-img>
      </v-list-item-avatar>
      <v-list-item-content style="margin-left: 20px">
        <v-list-item-title>{{ ship.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ ship.manufacturerCode }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-spacer></v-spacer>
<!--      <v-list-item-action v-if="edit && profile.verified && !defaultProfile" style="margin-right: 15px">-->
<!--        <v-btn color="green" outlined>-->
<!--          Set to default-->
<!--        </v-btn>-->
<!--      </v-list-item-action>-->
<!--      <v-list-item-action v-if="edit && !profile.verified" style="margin-right: 15px">-->
<!--        <v-btn color="yellow" outlined @click="overlay = true">-->
<!--          Must verify before using-->
<!--        </v-btn>-->
<!--      </v-list-item-action>-->

<!--      <v-list-item-action v-if="!edit && !profile.verified">-->
<!--        <v-btn @click="overlay = true" icon>-->
<!--          <v-icon color="yellow">mdi-alert-circle-outline</v-icon>-->
<!--        </v-btn>-->
<!--      </v-list-item-action>-->
<!--      <v-list-item-action v-if="!edit && profile.verified && defaultProfile">-->
<!--        <v-btn icon>-->
<!--          <v-icon color="green">mdi-check-circle</v-icon>-->
<!--        </v-btn>-->
<!--      </v-list-item-action>-->
<!--      <v-list-item-action v-if="edit">-->
<!--        <v-btn icon @click="deleteProfile" :disabled="defaultProfile">-->
<!--          <v-icon color="red">mdi-trash-can-outline</v-icon>-->
<!--        </v-btn>-->
<!--      </v-list-item-action>-->
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'hanger-row',
  props: {
    ship:{}
  },
  data() {
    return {
      overlay: false,
      verificationCode: ""
    }
  },
  methods: {

  }
}
</script>