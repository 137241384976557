<template>
    <div class="the-pilot">
      <v-flex>


      <v-card
          class="mx-auto rounded"
      >

        <v-navigation-drawer permanent>
          <v-img height="100" src="https://robertsspaceindustries.com/rsi/static/images/account/avatar_default_big.jpg"></v-img>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="'https://robertsspaceindustries.com' + me.rsiProfileImgUrl"></v-img>
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item link>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ me.userName }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ me.email }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-menu-down</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list
              nav
          >
            <v-list-item-group

                color="primary"
            >
              <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  :to="{name:item.location}"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-card>
      </v-flex>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
      name: 'PilotSidebar',
      computed: {
        ...mapGetters({ me: "getMyPilot" }),
      },
      data() {
        return {
          items:[
            {text: 'Hanger', icon: 'mdi-garage-open-variant',location:'the-hanger'},
            {text: 'Profile', icon: 'mdi-information',location:'the-profile'}
          ]
        }
      },
    };
</script>
