<template>
  <amplify-sign-in header-text="SC Fleets Login" slot="sign-in" />
</template>

<script>
export default {
  name: "SignIn"
}
</script>

<style scoped>

</style>