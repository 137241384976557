<template>
  <div class="TheHanger">
    <v-flex>
      <v-card class="grey darken-3">
        <v-card-title>Hanger <v-btn color="blue" @click="getMyPilot" icon><v-icon>mdi-refresh</v-icon></v-btn></v-card-title>
        <v-list class="grey darken-2" min-height="400" three-line>
          <hanger-row v-for="(ship) in me.ships" :ship="ship" :key="ship.id"/>
          <v-list-item class="grey darken-1" v-if="adding">
            <v-list-item-content class="grey darken-1">
              <v-text-field
                  label="Add RSI Handle"

                  hide-details="auto"
                  v-model="shipId"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn @click="addNewShip" v-if="adding">
            Save
          </v-btn>
          <v-btn v-else-if="edit" @click="toggleEdit">
            Done
          </v-btn>
          <v-btn v-else @click="toggleAdding">
            <v-icon color="blue lighten-1">mdi-plus</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="!edit || !adding" @click="toggleEdit">
            <v-icon color="blue lighten-1">mdi-cog</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import HangerRow from "@/components/profile/hanger-content/HangerRow";

export default {
  name: 'TheHanger',
  components: {HangerRow},
  data() {
    return {
      edit: false,
      overlay: true,
      adding: false,
      shipId:''
    }
  },
  computed: {
    ...mapGetters({me: "getMyPilot"}),
  },
  methods: {
    toggleAdding() {
      this.adding = !this.adding
    },
    toggleEdit() {
      this.edit = !this.edit
    },
    save() {
      this.edit = !this.edit
    },
    addNewShip() {
      console.log('Saving handle: ',this.shipId)
      this.$store.dispatch("addNewShip",this.shipId);
      this.toggleAdding()
    },
    getMyPilot(){
      this.$store.dispatch("requestMyPilot");
    }
  }
};
</script>
