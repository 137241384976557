import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../pages/TheDashboard.vue'
import AdminConsole from '../pages/AdminConsole.vue'
import TheShips from '../components/admin-console/TheShips.vue'
import DataLoaders from '../components/admin-console/DataLoaders.vue'
import PilotProfile from '../pages/PilotProfile.vue'
import FleetListings from "@/pages/FleetListings";
import TheHanger from "@/components/profile/hanger-content/TheHanger";
import TheProfile from "@/components/profile/pilot-content/TheProfile";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'the-dashboard' }
  },
  {
    path: '/dashboard',
    name: 'the-dashboard',
    component: Dashboard
  },
  {
    path: '/fleets',
    name: 'fleet-listings',
    component: FleetListings
  },
  {
    path: '/pilot',
    name: 'pilot-profile',
    component: PilotProfile,
    children: [
      {
        path:'hanger',
        name: 'the-hanger',
        components: {
          profile: TheHanger
        },
      },
      {
        path:'profile',
        name: 'the-profile',
        components: {
          profile: TheProfile
        },
      }
    ]
  },
  // {
  //   path: 'ships',
  //   name: 'the-ships',
  //   component: TheShips
  // },
  {
    path: '/admin',
    name: 'admin-console',
    component: AdminConsole,
    children: [
      {
        path: 'ships',
        name: 'admin-console-ships',
        components: {
          admin: TheShips
        },
      },
      {
        path: 'loaders',
        name: 'admin-console-loaders',
        components: {
          admin: DataLoaders
        },
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
