<template>
    <div class="FleetListings">
        <pre v-text="$attrs"/>
    </div>
</template>

<script>
    export default {
        name: 'Fleetlistings'
    };
</script>
