<template>
  <div>

    <v-container fluid>
      <v-row>
        <v-col offset-sm="0" offset-md="1" offset-lg="2" md="2" xl="1" style="max-width: 215px">
            <pilot-sidebar></pilot-sidebar>
        </v-col>

        <v-col sm="8" md="6" style="min-height: 500px">
<!--          <v-sheet class="rounded-lg" style="min-height: 70vh">-->
            <router-view name="profile"></router-view>
<!--          </v-sheet>-->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PilotSidebar from "@/components/profile/pilot-sidebar/PilotSidebar";

export default {
  components: {PilotSidebar},
  computed: {
    ...mapGetters({me: "getMyPilot"}),
  },
  created() {
    this.getMyPilot()
  },
  methods:{
    getMyPilot(){
      this.$store.dispatch("requestMyPilot");
    }
  }
};
</script>
