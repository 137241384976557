import axios from 'axios'
import { Auth } from "aws-amplify";
const axiosApiInstance = axios.create();

// Interceptor for adding cognito bearer token to each request
axiosApiInstance.interceptors.request.use( (config) => {
    return Auth.currentSession().then((userSession) => {
        const token = userSession.getIdToken().getJwtToken()
        config.headers.Authorization =  "Bearer " + token;
        return config;
    })
  },(error) => {
    // Do something with request error
    return Promise.reject(error);
  });

export default axiosApiInstance;
