import Api from '../utils/Api'

export default {
  state: {
    ships: [],
  },
  mutations: {
    SET_SHIPS: (state, ships) => {
      state.ships = ships;
    },
  },
  actions: {
    requestShips: ({ commit }) => {
      Api.get(`${process.env.VUE_APP_API_SERVER}/ships/`)
        .then((response) => {
          commit("SET_SHIPS", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    requestShip: (id) => {
      Api.get(`${process.env.VUE_APP_API_SERVER}/ships/${id}/`)
        .then((response) => {
          this.commit("SET_SHIPS", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  getters: {
    getShips: (state) => {
      return state.ships;
    },
  },
};
