import Vue from 'vue'
import Vuex from 'vuex'

import shipStore from './ships'
import pilotStore from './pilots'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ships: shipStore,
    pilots: pilotStore
  }
})
