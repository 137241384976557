<template>
  <div>
    <v-list-item>
      <v-overlay
          :absolute="true"
          :value="overlay"
          opacity="0.9"
      >
        <v-row>
          <v-col>
            <v-text-field
                label="Verification Code"
                hide-details="auto"
                v-model="verificationCode"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
                color="success"
                @click="verifyProfile"
            >
              Verify
            </v-btn>
            <v-btn
                color="alert"
                @click="overlay = false"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>


      </v-overlay>
      <v-list-item-avatar>
        <v-img
            :alt="`${handle} avatar`"
            :src="'https://robertsspaceindustries.com' + profile.rsiProfileImgUrl"
        ></v-img>
      </v-list-item-avatar>
      <v-list-item-content style="margin-left: 20px">
        <v-list-item-title>{{ handle }}</v-list-item-title>
        <v-list-item-subtitle>{{ profile.ueeRecordNumber }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-spacer></v-spacer>
      <v-list-item-action v-if="edit && profile.verified && !defaultProfile" style="margin-right: 15px">
        <v-btn color="green" outlined>
          Set to default
        </v-btn>
      </v-list-item-action>
      <v-list-item-action v-if="edit && !profile.verified" style="margin-right: 15px">
        <v-btn color="yellow" outlined @click="overlay = true">
          Must verify before using
        </v-btn>
      </v-list-item-action>

      <v-list-item-action v-if="!edit && !profile.verified">
        <v-btn @click="overlay = true" icon>
          <v-icon color="yellow">mdi-alert-circle-outline</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action v-if="!edit && profile.verified && defaultProfile">
        <v-btn icon>
          <v-icon color="green">mdi-check-circle</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action v-if="edit">
        <v-btn icon @click="deleteProfile" :disabled="defaultProfile">
          <v-icon color="red">mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'profile-row',
  props: {
    edit: {},
    profile: {},
    handle: {},
    defaultProfile: {}

  },
  data() {
    return {
      overlay: false,
      verificationCode: ""
    }
  },
  methods: {
    verifyProfile() {
      console.log('Sending verification code:', this.verificationCode)
      this.$store.dispatch("verifyRsiProfile", {rsiHandle: this.handle, verificationCode: this.verificationCode})
      this.overlay = false
    },
    deleteProfile() {
      console.log('Deleting handle: ', this.handle)
      this.$store.dispatch("removeRsiProfileMyPilot", this.handle);
    }
  }
}
</script>