<template>
  <div>
    <pre v-text="$attrs"/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {},
    };
  },
  methods: {},
};
</script>
