<template>
  <div>
    <h2>Data Loaders</h2>
    <v-btn @click="reloadShips">Reload Ship</v-btn>
  </div>
</template>

<script>
import Api from "../../utils/Api";

export default {
  methods: {
    reloadShips() {
      Api.get(`${process.env.VUE_APP_API_SERVER}/loader/ships/`)
        .then(() => {
          console.log("reloaded ships");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
