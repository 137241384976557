<template>
  <div id="app">
    <amplify-authenticator>
      <sign-in></sign-in>
      <sign-up></sign-up>
      <v-app>
        <nav-header></nav-header>
        <!-- Sizes your content based upon application components -->
        <v-main id="main" class="">
          <!-- Provides the application the proper gutter -->
          <v-container fluid>
            <!-- If using vue-router -->
            <router-view></router-view>
          </v-container>
        </v-main>

        <nav-footer></nav-footer>
      </v-app>
    </amplify-authenticator>
  </div>
</template>

<script>
import NavHeader from "@/components/navigation/NavHeader";
import NavFooter from "@/components/navigation/NavFooter"
import SignUp from "@/components/auth/SignUp";
import SignIn from "@/components/auth/SignIn";

export default {
  components: { NavHeader, NavFooter, SignIn, SignUp },
};
</script>

<style scoped>
</style>
